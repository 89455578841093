<template>
  <section class="resposta-multipla-escolha">
    <v-row>
      <v-col cols="12" md="12" sm="12" v-if="imagemPergunta">     
        <p class="text-size-90 mt-1">
          <v-img max-height="300" max-width="400" :src="imagemPergunta"></v-img>
        </p>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-radio-group
          v-model="respostaQuestionario"
          column  
          >
          <v-radio
            v-for="(item, index) in convertPergunta(alternativas)"
            :key="index"
            :value="item.id"
            class="mt-1"
            style="white-space: normal"
            @click="defineRespostaAlternativa(indexPergunta)"
            :disabled="disabledinput"
          >
            <template v-slot:label>
              <span
                v-show="!errors.has(`respostaQuestionario`)"
                style="color: black; line-height: 20px"
                class="text-size-90"
              >
                {{ item.descricao }}
              </span>
            </template>
          </v-radio>
          <span
            v-show="errors.has(`respostaQuestionario`)"
            style="color: red"
            v-html="'Marque ao menos uma opção!'"
          >
          </span>
        </v-radio-group>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "resposta-radio-escolha",
  inject: ["$validator"],
  props: [
    "indexPergunta",
    "textoPergunta",
    "imagemPergunta",
    "alternativas",
    "uuidPergunta",
    "codeFormulario",
    "categoria",
    "subcategoria",
  ],
  components: {},
  data() {
    return {
      respostaQuestionario: [],
      disabledinput: false
    };
  },
  methods: {
    convertPergunta(item) {
      const array = Array.isArray(item) ? item : JSON.parse(item);
      array.map(item => {
        item.id = parseInt(item.id)
      })
      return array; 
    },
    defineRespostaAlternativa(indexPergunta) {
      // Prepara o objeto da pergunta com suas respostas para salvar no banco
      this.disabledinput = true;
      const data = {
        indexPergunta: indexPergunta,
        uuid_pergunta: this.uuidPergunta,
        code_formulario: this.codeFormulario,
        uuid_escala_itens: "",
        uuid_alternativa: "",
        opcao: this.respostaQuestionario,
      };

      if (this.categoria == "FI" && this.subcategoria == "FAV") {
        data.uuid_escala_itens = this.respostaQuestionario;
      } else {
        data.uuid_alternativa = this.respostaQuestionario.id;
      }
      // Atualiza ou Adiciona resposta dentro do array respostas
      if (
        this.respostas &&
        this.respostas.length > 0 &&
        this.respostas.some((r) => r.indexPergunta === this.indexPergunta)
      ) {
        this.respostas.splice(indexPergunta, 1, data);
      } else {
        this.respostas.push(data);
      }
      this.disabledinput = false;
      // Atualiza data de resposta local do componente pai
      this.$emit("atualiza-resposta-local", data, "radio");
    },
    /**
     * [validarArrayRespostas description]
     * @description Pecorre o array de respostas salvas no vuex
     * @author Johnny Santos <johnny.santos@sociisrh.com.br>
     */
    validarArrayRespostas() {
      if (this.respostas == undefined || this.respostas.length === 0)
        return null;

      this.verificaPerguntas();
    },
    /**
     * [verificaPerguntas description]
     *
     * @description Verifica se o index da pergunta atual está dentro do array de resposta e se o array de resposta atual está vazio, caso estiver
     * o array de resposta recebe as opçoes da pergunta atual, se não ele realiza uma verificação no array de respostas atuais.
     * @author Johnny Santos <johnny.santos@sociisrh.com.br>
     */
    verificaPerguntas() {
      if (this.respostas.some((r) => r.uuid_pergunta === this.uuidPergunta)) {        
        const result = this.respostas.filter(
          (el) => el.uuid_pergunta === this.uuidPergunta
        );
        if (result.length > 0) {
          if (result[0].opcao) {
            this.respostaQuestionario = parseInt(result[0].opcao); 
          }
        }
      } else {
        this.verificaRespostaQuestionario();
      }
    },
    /**
     * [verificaRespostaQuestionario description]
     *
     * @description Verifica se no array de respostas atuais do componente tem opçoes de perguntas diferentes, caso exista ele irá remover.
     * @author Johnny Santos <johnny.santos@sociisrh.com.br>
     * @return  {object}   Retorna objeto de opcoes
     */
    verificaRespostaQuestionario() {      
      if (this.respostas.some((r) => r.uuid_pergunta === this.uuidPergunta)) {
        const result = this.respostas.filter(
          (el) => el.uuid_pergunta === this.uuidPergunta
        );
        if (result.length > 0) {
          if (result.uuid_escala_itens !== "") {
            this.respostaQuestionario = result[0].uuid_escala_itens.toString();
          } else {
            this.respostaQuestionario = parseInt(result[0].opcao);
          }
        }
      }
    },
  },
  /**
   * [beforeMount description]
   * @description Responsavel por validar se existe respostas selecionadas no da pergunta atual
   * @author Johnny Santos <johnny.santos@sociisrh.com.br>
   */
  beforeMount() {
    this.validarArrayRespostas();
  },
  /**
   * [beforeUpdate description]
   * @description Responsavel por verificar as opções selecionadas em tempo real
   * @author Johnny Santos <johnny.santos@sociisrh.com.br>
   */
  beforeUpdate() {
    this.validarArrayRespostas();
  },
  /**
   * [destroyed description]
   * @description Responsavel por limpar o array de perguntas atuais após o componente ser removido do life cycle
   * @author Johnny Santos <johnny.santos@sociisrh.com.br>
   */
  destroyed() {
    this.respostaQuestionario = [];
  },
  /**
   * [watch indexPergunta]
   * @description É utilizado quando o componente não é destruido, mas as perguntas são diferentes,
   * ele irá limpar o array de perguntas atuais para seguir o fluxo.
   * @author Johnny Santos <johnny.santos@sociisrh.com.br>
   */
  watch: {
    indexPergunta: function () {
      // Verificar se existe um objeto resposta em respostas com mesmo indice da pergunta atual
      if (this.respostas.some((r) => r.uuid_pergunta === this.uuidPergunta)) {
        // Se existir, respostaQuestionario recebe esse objeto
        const resposta = this.respostas[this.indexPergunta].opcao
        this.respostaQuestionario = resposta;
      } else {
        this.respostaQuestionario = [];
      }
    },
  },
  computed: {
    respostas: {
      get() {
        return this.$store.getters["pesquisas/getRespostas"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setRespostas", value);
      },
    },
  },
};
</script>

<style lang="scss">

</style>
